<template>
  <div v-if="!loading" class="row">
    <b-modal ref="groupsmodal" no-enforce-focus hide-header>
      <form @submit.prevent="saveGroups">
        <b-form-group label="User Groups" label-for="groups">
          <blueprint-search-input
            id="groups"
            v-model="selectedItem.groups"
            placeholder="Type to search"
            api-route-path="groups"
            :state="groupsState('groups')"
            :multiple="true"
          />
        </b-form-group>
        <input type="submit" style="visibility: hidden;">
      </form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="saveGroups()">
          Save
        </b-button>
        <b-button variant="dark" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <b-modal ref="passwordmodal" no-enforce-focus hide-header>
      <form @submit.prevent="savePassword">
        <b-form-group label="New one-time password" label-for="password">
          <b-form-input id="password" v-model="newPassword" type="text" placeholder="" />
        </b-form-group>
        <input type="submit" style="visibility: hidden;">
      </form>
      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="success" @click="savePassword()">
          Save
        </b-button>
        <b-button variant="dark" @click="cancel()">
          Cancel
        </b-button>
      </template>
    </b-modal>
    <div class="col">
      <b-card title="Users">
        <blueprint-data ref="blueprint" api-route-path="users" :fields="fields" :new-default-values="newDefaultValues" :where="where" :q="q">
          <template v-slot:search>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Search">
              <b-form-input id="name" v-model="q" type="text" placeholder="Search by name, email, phone..." />
            </b-form-group>
            <b-form-group label-cols-sm="4" label-cols-lg="3" label="Groups">
              <blueprint-search-input
                id="groups"
                v-model="groups"
                :multiple="true"
                placeholder="Type to search Groups"
                label="name"
                track-by="id"
                api-route-path="groups"
              />
            </b-form-group>
          </template>

          <template v-slot:username="{ item }">
            <span v-if="usernameAttribute === 'EMAIL'">
              {{ item.email }}
            </span>
            <span v-if="usernameAttribute === 'PHONE'">
              {{ item.phone }}
            </span>
            <span v-if="usernameAttribute === 'USERNAME'">
              {{ item.username }}
            </span>
            <!--- <b-badge variant="dark">me</b-badge> v-if="user == me" -->
          </template>
          <template v-slot:verified="{ item }">
            <b-badge v-if="item.verified" variant="success" style="margin-right:.2rem">
              yes
            </b-badge>
            <b-badge v-if="!item.verified" variant="danger" style="margin-right:.2rem">
              no
            </b-badge>
            <br>
            <a v-if="!item.verified" href="#" @click.prevent="resendInvitation(item)">Resend Invitation</a>
            <br><br>
            <a v-if="!item.verified" href="#" @click.prevent="verifyByAdmin(item)">Set user verified</a>
          </template>
          <template v-slot:actions="{ item }">
            <a href="#" @click.prevent="userPassword(item)"><fa-icon icon="key" />Reset password</a>
            <a href="#" @click.prevent="userGroups(item)"><fa-icon icon="user-friends" />Groups</a>
          </template>

          <template v-slot:default="{ state, item }">
            <b-form-group v-if="usernameAttribute=='USERNAME'" label="Username" label-for="username">
              <b-form-input id="name" v-model="item.username" :state="state('username')" type="text" placeholder="Username" />
            </b-form-group>
            <b-form-group label="E-mail" label-for="email" :description="usernameAttribute=='EMAIL'?'E-mail address is used as username': ''">
              <b-form-input id="email" v-model="item.email" :state="state('email')" type="email" placeholder="E-mail address" />
            </b-form-group>
            <b-form-group label="Phone" label-for="phone" :description="usernameAttribute=='PHONE'?'Phone number is used as username': ''">
              <b-form-input id="phone" v-model="item.phone" :state="state('phone')" type="tel" placeholder="Phone number with country code" />
            </b-form-group>
            <b-form-group label="First Name" label-for="firstname">
              <b-form-input id="firstname" v-model="item.firstname" :state="state('firstname')" type="text" placeholder="First Name" />
            </b-form-group>
            <b-form-group label="Last Name" label-for="lastname">
              <b-form-input id="lastname" v-model="item.lastname" :state="state('lastname')" type="text" placeholder="Last Name" />
            </b-form-group>
            <b-form-group label="Role" label-for="role">
              <b-form-select id="role" v-model="item.role" :state="state('role')" :options="roles" />
            </b-form-group>
          </template>
        </blueprint-data>
      </b-card>
    </div>
  </div>
</template>

<script>
import utils from '../../../libs/utils.vue';

export default {
  data () {
    return {
      loading: true,
      userId: undefined,
      errors: {},
      selectedItem: {},
      where: {},
      groups: [],
      q: null,
      newPassword: '',
      newDefaultValues: {
        role: 'USER',
      },
      roles: [
        { value: 'ADMIN', text: 'Administrator' },
        { value: 'MANAGER', text: 'Manager' },
        { value: 'BILLINGMANAGER', text: 'Billing Manager' },
        { value: 'AUDITOR', text: 'Auditor' },
        { value: 'USER', text: 'User' },
      ],
      fields: [
        {
          key: 'username',
          sortable: true,
          slot: true,
        },
        {
          key: 'email',
          sortable: true,
        },
        {
          key: 'phone',
          sortable: true,
        },
        {
          key: 'firstname',
          sortable: true,
        },
        {
          key: 'lastname',
          sortable: true,
        },
        {
          key: 'role',
          sortable: true,
          label: 'Role',
        },
        {
          key: 'verified',
          sortable: true,
          slot: true,
        },
      ],
    };
  },
  computed: {
    usernameAttribute () {
      return this.$store.getters.org ? this.$store.getters.org.cognitoUsernameAttribute : 'USERNAME';
    },
  },
  watch: {
    groups () {
      this.where = {
        ...utils.parseWhereItem('groups.id', this.groups),
      };
    },
  },
  async created () {
    if (!this.$store.getters.org) {
      await this.$store.dispatch('FETCH_ORG');
    }
    this.loading = false;
  },
  methods: {
    success (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'success',
      });
    },
    error (title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'error',
      });
    },
    err (err) {
      this.error('Error', err.response && err.response.data ? err.response.data.message || err.message : err.message);
      this.errors = err.response && err.response.data ? err.response.data.details || {} : {};
      throw err;
    },
    resendInvitation (user) {
      const { id } = user;
      this.$http.post(`users/${id}/invitation`, {})
        .then(() => this.success('Done', 'Invitation sent'))
        .catch(this.err);
    },
    verifyByAdmin (user) {
      const { id } = user;
      this.$http.post(`users/${id}/verify`, {})
        .then(() => this.$refs.blueprint.getData())
        .then(() => this.success('Done', 'User verified'))
        .catch(this.err);
    },
    groupsState (item) {
      return (this.errors[item] ? false : null);
    },
    userGroups (item) {
      const { id } = item;
      this.selectedItem = {};
      this.userId = id;
      this.$http.get(`users/${id}/groups`)
        .then((res) => {
          this.selectedItem.groups = res.data;
          this.errors = {};
          this.$refs.groupsmodal.show();
        })
        .catch(this.err);
    },
    saveGroups () {
      this.$http.patch(`users/${this.userId}/groups`, this.selectedItem.groups || [])
        .then(() => {
          this.$refs.groupsmodal.hide();
        })
        .catch(this.err);
    },
    userPassword (item) {
      const { id } = item;
      this.selectedItem = {};
      this.newPassword = '';
      this.userId = id;
      this.errors = {};
      this.$refs.passwordmodal.show();
    },
    savePassword () {
      this.$http.post(`users/${this.userId}/password`, { password: this.newPassword })
        .then(() => {
          this.$refs.passwordmodal.hide();
        })
        .catch(this.err);

    },
  },
};
</script>
